import PropTypes from "prop-types"
import React from "react"
import LogoMain from "../images/logo.inline.svg"
import { Dropdown, Container } from 'react-bootstrap'
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import BtnA from "../images/btn_a.inline.svg"
import Onweb from "../images/onweb.inline.svg"
import Inmail from "../images/inm.inline.svg"
import Bymail from "../images/bye.inline.svg"
import Productsicon from "../images/productsicon.inline.svg"
import Arrowmobile from "../images/arrow_mobile.inline.svg"

function dropmenu() {

  var testicol = document.querySelector('.openmenumobile');
  testicol.classList.toggle('openul');

}

function bodyoverflow() {

  var menu = document.querySelector('body');
  menu.classList.toggle('noscrollbody');

}

const Header = ({ siteTitle }) => (


  <header>
    <div className="bodyclass"></div>
    <Container>
      <div className="logo">
       <Link to="/">
         <LogoMain/></Link>
      </div>
  <nav className="navbar">
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
    <li className="nav-item active showsub">
        <a id="productsmenu" className="nav-link" href="#"><Productsicon />Products</a>
        <ul className="products-dropdown">
            <li>
              <Link to="/on_website">
                <h4>On <strong>website</strong><Onweb /></h4>
                <p>A Simple checkout system with plugins</p>
              </Link>
            </li>
            <li>
              <Link to="/in_person">
                <h4>In <strong>person</strong><Inmail /></h4>
                <p>A simple and efficient point of sale (POS) system</p>
              </Link>
            </li>            
            <li>
              <Link to="/by_email">
              <h4>By <strong>email</strong><Bymail /></h4>
                <p>Create email invoices for your customers</p>
              </Link>
            </li>
            <li>
            <Link to="/accepting-wallet">
              <h4>Accepting <strong>payments</strong></h4>
                <p>How can customers pay you?</p>
              </Link>
            </li>
        </ul>
      </li>
      <li className="nav-item">
      <a className="nav-link" href="https://test-app.dagpay.io/documentation" target="_blank">Documentation</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="https://help.dagpay.io/">Support</a>
      </li>
      <li className="nav-item active">
        <a className="nav-link" href="https://test-app.dagpay.io/">Log In</a>
      </li>
      <li className="nav-item blue-menu-item">
        <a className="nav-link" href="https://test-app.dagpay.io/sign-up/create-account/">Sign Up</a>
      </li>
    </ul>
  </div>
</nav>

<Dropdown className="mobile-menu">
          <Dropdown.Toggle variant="mobile" id="dropdown-basic" className="mobile-menu-btn">
          <div className="hamburger" onClick={bodyoverflow}>
                <span></span>
            </div>
          </Dropdown.Toggle>
          <div className="sign-up-btn">
          <a href="https://test-app.dagpay.io/login" title="Sign up">Log in</a>
      </div>
          <Dropdown.Menu className="mobile-menu-wrap">
          <ul id="nav" className="d-xs-none d-md-inherit d-lg-inherit">
          <li className="nav-item active showsub openmenumobile openul">
          <a id="productsmenu" className="nav-link showdrop" href="#" onClick={dropmenu} ><Arrowmobile />Products</a>
        <ul className="products-dropdown">
            <li>
              <a href="/on_website" onClick={bodyoverflow}>
                <h4>On <strong>website</strong><Onweb /></h4>
              </a>
            </li>
            <li>

              <a href="/in_person" onClick={bodyoverflow}>
                <h4>In <strong>person</strong><Inmail /></h4>
                </a>

            </li>            
            <li>

              <a href="/by_email" onClick={bodyoverflow}>          
                <h4>By <strong>email</strong><Bymail /></h4>
                </a>

            </li>
            <li>
            <a href="/accepting-wallet" onClick={bodyoverflow}>  
              <h4>Accepting <strong>payments</strong></h4>
            </a>
            </li>
        </ul>
      </li>
      <li className="nav-item">
      <a className="nav-link" href="https://test-app.dagpay.io/documentation" target="_blank">Documentation</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="https://help.dagpay.io/">Support</a>
      </li>
      <li className="nav-item active">
        <a className="nav-link" href="https://test-app.dagpay.io/">Log In</a>
      </li>
      <li className="nav-item blue-menu-item">
        <a className="nav-link" href="https://test-app.dagpay.io/sign-up/create-account/">Sign Up Now <BtnA/></a>
      </li>
            </ul>
            </Dropdown.Menu>
        </Dropdown>
</Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header