import React, { useEffect } from "react"
import {graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Phone from "../images/phone.inline.svg"

import First from "../images/first.inline.svg"
import Second from "../images/second.inline.svg"
import Third from "../images/third.inline.svg"

function InPerson({ data, pageContext: { lang, currentPath } }) {
  useEffect(() => {
    var scrollPosition = window.scrollY;
    
    window.addEventListener('scroll', function() {
    
        scrollPosition = window.scrollY;
    
        if (scrollPosition > 500) {

            document.getElementById('animationline').classList.add('active')
        } else {
         
        }
    
    });
  }, [])
  return (
  <Layout>
    <SEO title="Home" />
      <Container className="above in-person-page">
        <Row>
          <Col>
        <div className="above-tag in-person-icon"><Phone /><span>In person</span></div>
        <h1>Looking to accept dagcoins in person at <strong>your brick</strong>-and-<strong>mortar business?</strong></h1>
        <p>The easy-to-use POS application is the perfect solution for you.</p>
        </Col>
        <Col className="images-above">
        <div className="abovetwo"><Img fluid={data.mockup.childImageSharp.fluid} /></div>
        </Col>
        </Row>
      </Container>
<Container className="accepting">
    <Row>
        <Col><h3>Accepting payments from your customers is as easy as <span>1-2-3</span></h3></Col>
    </Row>
    <Row className="acceptingrow">
        <div id="animationline"></div>
        <Col>
        <First />
        <h4>Simple and convenient to use for anyone</h4>
        </Col>
        <Col>
        <Second />
        <h4>Fast and secure way to accept payments</h4>
        </Col>
        <Col>
        <Third />
        <h4>Available at <a href="https://play.google.com/store/apps/details?id=org.dagcoin.dagpos&hl=en">Google Play store</a> (iOS coming soon!)</h4>
        </Col>
    </Row>
</Container>
  </Layout>
  )
}
export const query = graphql`
  query {
    mockup: file(relativePath: { eq: "mockup.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagetwo: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    imagethree: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    imagefour: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default InPerson
